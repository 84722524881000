/* --------------------------- */
/* GENERAL */
/* --------------------------- */



/* body {
  font-family: "Roboto",sans-serif;
} */

.txt-primary {
  color: #FD5D14 !important;
}



/* --------------------------- */
/* COMPONENTS & SPECIALS */
/* --------------------------- */



/* NAVBAR & FOOTER */



.navbar {
  position: fixed;
  z-index: 3;
}

.logo {
  height: 50px;
}

.navbar-toggler {
  color: white !important;
}

.bg-color {
  background-color: #040f28;
  background-image: -webkit-repeating-radial-gradient(center center, rgba(256, 256, 256, 0.2), rgba(256, 256, 256, 0.2) 1px, transparent 1px, transparent 100%);
  background-size: 0.1875rem 0.1875rem;
  padding: 1.5rem 0;
}

.bg-color2 {
  background-color: #040f28;
  background-image: -webkit-repeating-radial-gradient(center center, rgba(256, 256, 256, 0.2), rgba(256, 256, 256, 0.2) 1px, transparent 1px, transparent 100%);
  background-size: 0.1875rem 0.1875rem;
}

.nav-txt .nav-link,
.navbar-brand {
  color: white !important;
  font-size: 1.2rem;
  margin-right: 3rem;
}

.nav-txt .nav-link:hover,
.navbar-brand:hover {
  color: #FD5D14 !important;
  transition: 1s;
  margin-right: 3rem;
}

.social-links {
  font-size: 2rem;
  color: white !important;
}

.social-links:hover {
  font-size: 2rem;
  color: #FD5D14 !important;
  transition: 1s;
}



/* WP Link */



.fa-square-whatsapp {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 3;
  font-size: 4rem;
  color: rgb(37, 211, 102);
}



/* --------------------------- */
/* HOME */
/* --------------------------- */




/* WELCOME */



.home {
  /* height: 100vh; */
  padding-top: 5.8rem;
}

.carousel {
  width: 100vw;
  height: 600px;
}

.carousel-item img {
  width: 100vw;
}

.carousel-caption {
  top: 40%;
}

.c-fluidos {
  object-fit: cover;
  overflow: hidden;
  height: 600px;
}

.c-paisaje {
  object-fit: cover;
  overflow: hidden;
  height: 600px;
}



/* MINERIA */



.cont-mineria {
  /* position: relative; */
  background-image: url("../src/images/maquina.jpg");
  background-attachment: fixed;
  background-size: cover;
  min-height: 400px;
}

.txt-mineria {
  /* position: absolute; */
  background-color: rgba(255, 255, 255, 0.6);
  width: 50%;
  padding: 100px 25px 25px 25px;
  margin-left: auto;
  margin-right: auto;
  /* vertical-align: middle !important; */
}



/* US */



.us {
  margin-left: 8.5rem;
}

.us .bg-dark-radial {
  background-image: -webkit-repeating-radial-gradient(center center, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 1px, transparent 1px, transparent 100%);
  background-size: 0.1875rem 0.1875rem;
  height: 25rem;
  width: 18.75rem;
  position: relative;
}

.us .col-12.col-lg-5{
  margin-left: 0;
}

.us .usPhoto {
  position: absolute; 
  object-fit: cover;
  margin-top: 2rem;
  margin-left: -2rem;
  height: 25rem;
  width: 100%;
}

.servhome img {
  object-fit: cover;
  overflow: hidden;
  height: 200px;
  
}

.service-icon {
  margin-top: -3.125rem;
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6.25rem;
}

.service-icon i {
  color: #FD5D14 !important;
}

.service-icon i:hover {
  color: #FD5D14 !important;
  font-size: 4rem;
  transition: 0.5s;
}



/* ELECTRICIDAD */



.cont-electricidad {
  /* position: relative; */
  background-image: url("../src/images/electricidad.jpg");
  background-attachment: fixed;
  background-size: cover;
  min-height: 400px;
}

.txt-electricidad {
  /* position: absolute; */
  background-color: rgba(255, 255, 255, 0.6);
  width: 50%;
  padding: 100px 25px 25px 25px;
  margin-left: auto;
  margin-right: auto;
  /* vertical-align: middle !important; */
}



/* SERVICIOS */



.container {
  padding-top: 150px;
}

.card img {
  padding: 10px 10px 5px 10px;
}

.card .btn {
  margin: 5px 10px 10px 10px !important;
}



/* MARINA */



.cont-marina {
  /* position: relative; */
  background-image: url("../src/images/marina.jpg");
  background-attachment: fixed;
  background-size: cover;
  min-height: 400px;
}

.txt-marina {
  /* position: absolute; */
  background-color: rgba(255, 255, 255, 0.6);
  width: 50%;
  padding: 100px 25px 25px 25px;
  margin-left: auto;
  margin-right: auto;
  /* vertical-align: middle !important; */
}



/* TEST */



.red {
  background-color: red;
  height: 10vh;
}

.blue {
  background-color: blue;
  height: 10vh;
}

.jc-c{
  justify-content: center !important;
}
.d-flex{
  display: flex !important;
}
.ai-c{
  align-items: center !important;
}



/* --------------------------- */
/* RESPONSIVE */
/* --------------------------- */



@media (max-width: 765px) {
  .home{
    padding-top: 5.51rem;
  }
  .us{
    margin: 0;
  }
}

@media (max-width: 800px) {
  .home{
    padding-top: 5.51rem;
  }

  .us{
    margin: auto;
  }
  .us .col-12.col-lg-5{
    padding-left: 1.3rem;
  }
  .us .bg-dark-radial {
    right: -1rem;
  }
}